<template>
  <v-layout column>
    <v-flex>
      <v-container fluid grid-list-md pa-0>
        <v-layout justify-end align-top row wrap justify-space-between>
          <v-flex xs6>
            <RodoSearchFilters ref="filters"></RodoSearchFilters>
          </v-flex>
          <v-flex xs3>
            <v-layout justify-end>
              <v-flex>
                <v-menu
                  v-model="showDataPicker.start"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDate"
                      :label="$t('backup.dateFrom')"
                      readonly
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    locale="pl"
                    color="primary"
                    :max="endDate"
                    @change="showDataPicker.start = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex>
                <v-menu
                  v-model="showDataPicker.end"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDate"
                      :label="$t('backup.dateTo')"
                      readonly
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    locale="pl"
                    color="primary"
                    :min="startDate"
                    :max="currentDate"
                    @change="showDataPicker.end = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
          <div style="padding-top: 10px;">
            <v-btn color="primary" @click="generateReport">{{
              $t("global.generateReport")
            }}</v-btn>
          </div>
        </v-layout>
        <OperationHistoryTable
          :ref="tableRefName"
          :start-date="startDate"
          :end-date="endDate"
          :client-filters="clientFilters"
          @processing="dataProcessing"
        ></OperationHistoryTable>
        <v-layout>
          <PrintSave
            :disabled="!dataLoaded"
            :file-url="downloadReportUrl"
            @printClick="printReport"
          ></PrintSave>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>
<script>
import moment from "moment";
import Printer from "../common/HtmlPrinter";
import OperationHistoryTable from "../components/Reports/OperationHistoryTable";
import PrintSave from "../components/PrintSave";
import dateTimeFilters from "../mixins/date-time-filters";
import RodoSearchFilters from "../components/RodoSearchFilters";
import { ContactFilter } from "../common/Common.js";
import { EmitError } from "../event-bus";
import FileService from "../services/FileService.js"

export default {
  name: "ReportOperationHistory",
  components: { OperationHistoryTable, RodoSearchFilters, PrintSave },
  data() {
    const currentDate = dateTimeFilters.filters.dateFilter(moment());

    return {
      tableRefName: "table",
      dataLoaded: false,
      currentDate,
      startDate: dateTimeFilters.filters.dateFilter(
        moment().subtract(7, "days")
      ),
      endDate: currentDate,
      showDataPicker: { start: false, end: false },
      clientFilters: new ContactFilter("", "", "", ""),
      lastRequest: { clientFilters: {} }
    };
  },
  computed: {
    printTitle() {
      return `${this.$t("operationHistoryReport.operationHistoryFrom")} ${
        this.lastRequest.startDate
      } ${this.$t("global.to").toLowerCase()} ${this.lastRequest.endDate}`;
    },
    downloadReportUrl() {
      return FileService.ReportOperationHistoryUrl(
        this.lastRequest.startDate,
        this.lastRequest.endDate,
        this.lastRequest.clientFilters.firstname,
        this.lastRequest.clientFilters.surname,
        this.lastRequest.clientFilters.phone,
        this.lastRequest.clientFilters.mail);
    }
  },
  methods: {
    dataProcessing(loading) {
      this.dataLoaded = loading;
    },
    generateReport() {
      this.clientFilters =
        this.$refs["filters"].getDto()[0] || new ContactFilter("", "", "", "");

      if (this.clientFilters.validate()) {
        this.lastRequest = {
          startDate: this.startDate,
          endDate: this.endDate,
          clientFilters: this.clientFilters
        };
        this.$nextTick(this.$refs[this.tableRefName].getData);
      } else {
        EmitError(this.$t("rodo.searcherWrongLength"));
      }
    },
    printReport() {
      Printer.PrintElement(this.$refs[this.tableRefName].$el, this.printTitle, {
        landscape: true
      });
    }
  }
};
</script>
