<template>
  <v-layout justify-end @keyup.enter="$emit('filter')">
    <v-flex>
      <v-text-field
        v-model="filters.firstname"
        :label="$t('rodo.name')"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-text-field
        v-model="filters.surname"
        :label="$t('rodo.surname')"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-text-field
        v-model="filters.phone"
        :label="$t('rodo.phone')"
        single-line
        hide-details
        clearable
        return-masked-value
        :mask="phoneMask"
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-text-field
        v-model="filters.mail"
        :label="$t('rodo.mail')"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-flex>
  </v-layout>
</template>
<script>
import { ContactFilter } from "../common/Common.js";
export default {
  name: "RodoSearchFilters",
  data() {
    return {
      phoneMask: "#########",
      filters: new ContactFilter("", "", "", "")
    };
  },
  methods: {
    getDto() {
      return [this.filters];
    }
  }
};
</script>
